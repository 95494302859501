import React from 'react'
import {Link} from 'gatsby'
import style from './list.module.css'


const SL = ({items}) => {
	return(
		<>
			<div className={style.listSuggestContainer}>
				<div className={style.titleWrapper}>
					<h3>Most Popular</h3>
				</div>
				<div className={style.itemWrapper}>
					{
						items.map((item, index)=>{
							return(
								<div className={style.resourcesItem} key={index}>
									<h4 className={style.resourcesItemTitle}>
										{item.name.substring(0, 37)} {item.name.length > 37 ? '...' : ''}
									</h4>
									<div className={style.resourcesItemDesc}>
										<p className={style.resourcesP}>
											{item.excerpt.substring(0, 130)} {item.excerpt.length > 130 ? '...' : ''}
										</p>
									</div>
									<div className={style.viewMore}>
										<Link to={`/resources/${item.slug}`}>
											VIEW ARTICLE
										</Link>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className={style.moreDetails}>
					<Link to="/resources">
						More Article
					</Link>
				</div>
			</div>
		</>
	)
}

export default SL