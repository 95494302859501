import React from 'react'
import {graphql, useStaticQuery, Link} from "gatsby"
import SuggestList from './suggest-list'

const L = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiResources(limit: 3, sort: {order: DESC, fields: created_at}) {
			    edges {
			      node{
			        name
			        slug
			        excerpt
			      }
			    }
			}
		}
	`)

	let d = [];
	query.allStrapiResources.edges.forEach(({node})=>{
		d.push(node);
	})
	return (
		<>
			<SuggestList items={d}/>
		</>
	)
}

export default L