import React from "react"
import { graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo";
import SinglePost from '../components/single-page-resource/index'


const Resources = ({data}) => {
  let d = data.strapiResources;
  return(
    <Layout>
        <SEO title={d.name}/>
        <SinglePost data={d}/>
      </Layout>
  )
}

export default Resources

export const query = graphql`
  query ResourceTemplate($slug: String!) {
    strapiResources(slug: { eq: $slug }) {
      id
      name
      excerpt
      content
      slug
      created_at
      feature_image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
` 

